<template>
  <div class="welfareSearchResult">
    <!-- logo 搜索 company-->
    <search-com :searchValStr="searchValue" @search-btn="searchBtn"></search-com>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <!-- 商品列表 -->
    <search-good-list-com 
      style="margin-top: 21px;"
      v-if="goodAndMealList.length > 0"
      :list="goodAndMealList"
      :pagination="pagination"
      @handle-current-change="handleCurrentChange"
      @handle-size-change="handleSizeChange">
    </search-good-list-com>

    <section class="con-body" v-else>
      <div class="shopping_car_empty">
		  <img class="img" src="~/assets/img/welfareMall/search_bg.png" alt="">
        <div></div>
      </div>
    </section>
    
  </div>
</template>

<script>
import SearchCom from '@/components/welfareMallComp/SearchCom';
import SearchGoodListCom from '@/components/welfareMallComp/SearchGoodListCom';
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb';
import { getReqGoodAndMealList, getReqSearchName } from "@/network/welfareMall_api";
export default {
  components: { SearchCom, SearchGoodListCom, WelfareBreadcrumb },
  name: 'WelfareSearchResult',
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '搜索结果',
        }
      ],
      pagination: {
        pageIndex: 1,
        limit: 10,
        total: 0
      },
      goodAndMealList: [],
      searchValue: ''

    };
  },
  computed:{
  },
  watch:{
    //监听参数变化
    $route:{
      handler(){
        //console.log(this.$route.params.value)
        if(this.$route.params.value){
          this.searchValue = this.$route.params.value;
          this.getGoodAndMealList(this.searchValue);
        }
      }
    }
  },
  created() {
    if(this.$route.params.value){
      this.searchValue = this.$route.params.value;
      this.getGoodAndMealList(this.searchValue);
    }
  },
  methods: {
    // 点击搜索
    searchBtn (val) {
      // val 是从搜索组件中传递过来
      this.pagination.pageIndex = 1;
      this.pagination.limit = 10;
      this.getGoodAndMealList(val);
      this.searchValue = val;
    },
    // 获取搜索到的商品或礼包列表
    async getGoodAndMealList (name) {
      const {pageIndex, limit} = this.pagination;
      var res = await getReqGoodAndMealList({
        name: name? name : this.searchValue,
        pageIndex,
        limit
      })
      if(res.isSuccess){
        var goodAndMealList = res.data;
        let listData1 =[],listData2=[];
        // 过滤图片 显示第一张
        goodAndMealList.forEach((item)=>{
          item.mealUrl = item.mealUrl.split(';')[0];
          if(item.mealType==1){
            listData1.push(item)
          }else{
            listData2.push(item)
          }
        })
        let data = [listData1,listData2];
        this.goodAndMealList = data;
        this.pagination.total = res.count;
      }else{
        this.$message.error(res.msg)
      }
    },
    // 页面条数发生改变
    handleSizeChange(val){
      this.pagination.limit = val;
      this.pagination.pageIndex = 1;
      this.getGoodAndMealList(this.searchValue);
    },
    //页面页码发生改变 
    handleCurrentChange(val){
      this.pagination.pageIndex = val;
      this.getGoodAndMealList(this.searchValue);
    }
  },
};
</script>

<style scoped lang="less">
.welfareSearchResult{
  .shopping_car_empty{
      height: 600px;
      display: flex;
      justify-content: center;
      .img{
        width: 450px;
        height: 450px;
      }
    }
}
</style>
