<template>
    <footer class="layout-footer">
        <div class="page-center">
            <div class="block_box1">
                <!-- 产品与服务 -->
                <div class="product">
                    <a class="footer-title" :href="jumpLink+'/productsservices'">产品与服务</a>
                    <div class="product_way product-way-more-col">
                        <a :href="jumpLink+'/shebaodaijiao.html'">企业社保代理</a>
                        <a :href="jumpLink+'/depositservice.html'">社保账户托管</a>
                        <a :href="jumpLink+'/daifagongzi.html'">薪资代发</a>
                        <a :href="jumpLink+'/gongzitiao.html'">电子工资条</a>
                        <a :href="jumpLink+'/yuangonghuamingce.html'">员工花名册</a>
                        <a :href="jumpLink+'/zhinengruzhi.html'">智能入职</a>
                        <a :href="jumpLink+'/welfare.html'">节日福利</a>
                        <a :href="jumpLink+'/yuangongshangbao.html'">员工商保</a>
                        <a :href="jumpLink+'/jifenfuli.html'">积分福利</a>
                    </div>
                </div>
                <!-- 政策与支持 -->
                <div class="box_policy">
                    <a class="footer-title" :href="jumpLink+'/newslist.html'">政策与支持</a>
                    <div class="product_way product-way-more-col">
                        <a v-for="categoryItem of listCategory" :key="categoryItem.Id"
                            :href="jumpLink + `/NewsList/${categoryItem.Id}.html`">
                            {{categoryItem.Name}}
                        </a>
                       <a :href="jumpLink + '/newslist.html'">更多资讯</a>
                    </div>
                </div>
                <!-- 更多栏目 -->
                <div class="more_columns">
                    <p class="footer-title">更多栏目</p>
                    <div class="product_way product-way-more-col">
                        <h3><a :href="hrLink">HR文库</a></h3>
                        <h3><a href="/">福利商城</a></h3>
                        <h3><a :href="jumpLink+'/helpCenter.html'">帮助中心</a></h3>
                        <h3><a :href="jumpLink+'/noviceschool.html'">新手学堂</a></h3>
                        <h3><a :href="jumpLink+'/shebaocesuan.html'">五险一金计算器</a></h3>
                        <h3><a :href="jumpLink+'/geshuijisuanqi.html'">工资个税计算器</a></h3>
                        <h3><a :href="jumpLink+'/yanglaojinjisuanqi.html'">养老金计算器</a></h3>
                        <h3><a :href="jumpLink+'/yanchituixiujisuanqi.html'">延迟退休计算器</a></h3>
                        <h3><a :href="jumpLink+'/solution.html'">企业服务案例</a></h3>
                    </div>
                </div>
                <!-- 关注壹人事 -->
                <div class="about_ers">
                    <p class="footer-title">关注壹人事</p>
                    <a class="about_us" :href="jumpLink+'/aboutus.html'">关于壹人事</a>
                    <div class="follow_list">
                        <div class="follow_item">
                            <img class="pulse" src="~/assets/img/footer/fl-qrCode.jpg" alt="加入HR交流群">
                            <p class="text">加入HR交流群</p>
                        </div>
                        <div class="follow_item">
                            <img class="pulse" src="~/assets/img/footer/gzh-qrCode.png" alt="壹人事-企业服务">
                            <p class="text">壹人事企业服务</p>
                        </div>
                        <div class="follow_item">
                            <img class="pulse" src="~/assets/img/footer/kf-qrCode.png" alt="壹人事-员工服务">
                            <p class="text">壹人事员工服务</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block_box2">
                <p class="footer-title">联系我们：</p>
                <div class="contact">
                    <span class="tel"><img src="~/assets/img/footer/tel_icon.png" />400-189-1900</span>
                    <span class="contact_info">
                        <img src="~/assets/img/footer/email_icon.png" />电子邮箱&nbsp; ：service@1renshi.com
                    </span>
                    <span class="contact_info">
                        <img src="~/assets/img/footer/work_icon.png" />工作时间&nbsp; ：9: 00-18: 00
                    </span>
                </div>
                <div class="contact_btn">
                    <a class="contact_us" href="javascript:void(0)" onclick="javascript:window.open('https://www.echatsoft.com/visitor/pc/chat.html?companyId=12203&echatTag=website','','width=854,height=600,top=200,left=200')" rel="nofollow"><h3>在线咨询</h3></a>
                    <a class="proposal_btn" href="https://www.wjx.cn/vj/mBoSc9D.aspx" rel="nofollow">投诉建议</a>
                </div>
            </div>
            <div class="block_box3">
                <!-- 社保代理城市 -->
                <div class="jump_box">
                    <a :href="jumpLink+'/shebaodaijiao.html'"><span class="jump_title">社保代理城市：</span></a>
                    <div class="jump_list">
                        <a :href="jumpLink+'/beijingshebao.html'">北京</a>
                        <a :href="jumpLink+'/shanghaishebao.html'">上海</a>
                        <a :href="jumpLink+'/shenzhenshebao.html'">深圳</a>
                        <a :href="jumpLink+'/guangzhoushebao.html'">广州</a>
                        <a :href="jumpLink+'/hangzhoushebao.html'">杭州</a>
                        <a :href="jumpLink+'/chengdushebao.html'">成都</a>
                        <a :href="jumpLink+'/chongqingshebao.html'">重庆</a>
                        <a :href="jumpLink+'/changshashebao.html'">长沙</a>
                        <a :href="jumpLink+'/nanjingshebao.html'">南京</a>
                        <a :href="jumpLink+'/wuhanshebao.html'">武汉</a>
                        <a :href="jumpLink+'/suzhoushebao.html'">苏州</a>
                        <a :href="jumpLink+'/ningboshebao.html'">宁波</a>
                        <a :href="jumpLink+'/xiamenshebao.html'">厦门</a>
                        <a :href="jumpLink+'/shebaodaijiao.html'">更多城市</a>
                    </div>
                </div>
                <!-- 社保托管城市 -->
                <div class="jump_box">
                    <a :href="jumpLink+'/depositservice.html'"><span class="jump_title">社保托管城市：</span></a>
                    <div class="jump_list">
                        <a :href="jumpLink+'/beijingtuoguan.html'">北京</a>
                        <a :href="jumpLink+'/shanghaituoguan.html'">上海</a>
                        <a :href="jumpLink+'/shenzhentuoguan.html'">深圳</a>
                        <a :href="jumpLink+'/guangzhoutuoguan.html'">广州</a>
                        <a :href="jumpLink+'/hangzhoutuoguan.html'">杭州</a>
                        <a :href="jumpLink+'/chengdutuoguan.html'">成都</a>
                        <a :href="jumpLink+'/chongqingtuoguan.html'">重庆</a>
                        <a :href="jumpLink+'/changshatuoguan.html'">长沙</a>
                        <a :href="jumpLink+'/nanjingtuoguan.html'">南京</a>
                        <a :href="jumpLink+'/wuhantuoguan.html'">武汉</a>
                        <a :href="jumpLink+'/suzhoutuoguan.html'">苏州</a>
                        <a :href="jumpLink+'/jinantuoguan.html'">济南</a>
                        <a :href="jumpLink+'shenyangtuoguan.html'">沈阳</a>
                        <a :href="jumpLink+'/depositservice.html'">更多城市</a>
                    </div>
                </div>
                <!-- 员工福利 -->
                <div class="jump_box">
                    <a :href="jumpLink+'/welfare.html'"><span class="jump_title">员工福利：</span></a>
                    <div class="jump_list">
                        <a href="/">福利商城</a>
                        <a :href="jumpLink+'/welfare/TheSpringFestival.html'">春节福利</a>
                        <a :href="jumpLink+'/welfare/WomensDay.html'">妇女福利</a>
                        <a :href="jumpLink+'/welfare/TheDragonBoatFestival.html'">端午福利</a>
                        <a :href="jumpLink+'/welfare/Mid-AutumnFestival.html'">中秋福利</a>
                    </div>
                </div>
                <!-- 更多推荐 -->
                <div class="jump_box">
                    <span class="jump_title">更多推荐：</span>
                    <div class="jump_list more_list">
                        <a :href="jumpLink+'/shebaodaijiao.html'">立即参缴社保</a>
                        <a :href="jumpLink+'/gongzitiao.html'">免费试用电子工资条</a>
                        <a :href="jumpLink+'/yuangonghuamingce.html'">免费试用员工花名册</a>
                        <a :href="jumpLink+'/News/14196.html'">社保代缴操作帮助</a>
                        <a :href="jumpLink+'/News/13603.html'">电子工资条操作帮助</a>
                        <a :href="jumpLink+'/News/13588.html'">员工花名册操作帮助</a>
                        <a :href="jumpLink+'/News/15698.html'">薪资代发操作帮助</a>
                    </div>
                </div>
                <!-- 案例及方案 -->
                <div class="jump_box last_box">
                    <a class="jump_title" :href="jumpLink+'/solution.html'">案例及方案：</a>
                    <div class="jump_list">
                        <a :href="jumpLink+'/solution1.html'">企业员工异地社保代缴</a>
                        <a :href="jumpLink+'/solution2.html'">小微企业社保代缴</a>
                        <a :href="jumpLink+'/solution3.html'">大中型企业电子工资条发放</a>
                        <a :href="jumpLink+'/solution4.html'">小微企业电子工资条发放</a>
                        <a :href="jumpLink+'/solution.html'">更多案例及解决方案</a>
                    </div>
                </div>
            </div>
            <div class="block_box4">
                <p> 版权所有©2014~2023www.1renshi.com壹人事 保留所有权利 杭州嘉创弘达信息技术有限公司</p>
                <p>
                    <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank" rel="nofollow">浙ICP备20012791号-1</a>
                    <img src="~/assets/img/footer/beian.png" alt="备案图标" style="vertical-align: middle" />
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402004244" target="_blank" rel="nofollow">浙公网安备 33010402004244号</a>
                </p>
                <p>
                    【免责声明】本站部分资讯信息为互联网收集而来，如内容侵犯了您的版权，请与我们取得联系，我们会及时作出处理，谢谢合作！
                    <a :href="jumpLink+'/sitemap.txt'">网站地图</a>
                </p>
            </div>
        </div>
        <!-- 悬浮窗  -->
        <div class="suspension" v-if="suspensionShow && suspension">
            <div class="sus-content">
                <div class="su-content">
                    <span>
                        <a :href="suspension.URL">
                            <img :src="suspension.ImgURL" alt="活动图片" />
                        </a>
                        <img class="su-close" src="~/assets/img/header/su-close.png" alt="关闭" @click="suspensionShow =false"/>
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { getHeadCategoryList,getSuspensionContent} from 'network/welfareMall_api.js';
export default {
    data(){
        return{
          jumpLink: this.preUrlHome,
          jumpLoginLink:this.preUrlOld,
          communityLink:this.communityUrl,
          hrLink:this.hrUrl,
          listCategory: [],     //政策与支持
          suspension:{},         //悬浮窗信息
          suspensionShow:false, //悬浮窗显示
        }
    },
    created(){        
        this.GetPageCategoryList();
        this.getSuspension();
        let show = this;
        setTimeout(()=>{ show.suspensionShow = true;},2000)
    },   
    methods:{
        //获取政策与支持下拉内容 
        async GetPageCategoryList() {
            let res = await getHeadCategoryList();
            if(res.IsSuccess) {
                this.listCategory = res.Data.slice(0,17);
            }
        },
        async getSuspension(){
            let res = await getSuspensionContent();
            if(res.Data){
                this.suspension = res.Data[0]
            }
        }
    }
};
</script>
<style lang="less" scoped>
footer{
    clear: both;
    display: block;
    background: #191E2B;
    padding-top: 82px;
    .page-center{
        width: 1200px;
        margin:0 auto;
        .footer-title,
        .footer-title a{
            font-size: 18px;
            font-weight: 400;
            color: #fff;
            line-height: 20px;;
        }
        .block_box1{
            display: flex;
            justify-content: space-between;
            .product_way{
                margin-top: 30px;
                a{
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #AFB3BF;
                    line-height: 20px;
                    margin-bottom:20px;
                }
            }
            .product-way-more-col {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 280px;
                a {
                    margin-right: 45px;
                }
            }
            
            /* 关于壹人事 */
            .about_ers{
                display: inline-block;
                width: 350px;
                .footer-title{
                    display: inline-block;
                    margin-left: 76px;
                }
                .about_us{
                    display:inline-block;
                    width: 110px;
                    height: 34px;
                    border-radius: 17px;
                    border: 1px solid #75C9BE;
                    font-size: 14px;
                    font-weight: 400;
                    color: #69E8CE;
                    line-height: 34px;
                    text-align:center;
                    margin-left:30px;
                    position:absolute;
                    margin-top:-8px;
                }
                .follow_list{
                    display: inline-block;
                    margin-top: 30px;
                    margin-left: 5px;
                    .follow_item{
                        float: left;
                        img{
                            width: 100px;
                            height: 100px;
                            background-size: cover;
                            border-radius: 5px;
                        }
                        p{
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            color:#AFB3BF;
                            text-align: center;
                            margin:6px 0;
                        }
                        &:nth-child(2n){
                            margin: 0 20px;
                        }
                        &:nth-child(1) p{
                            color:#fff;
                        }
                    }
                    
                }
            }
        }
        /* 联系我们 */
        .block_box2{
            line-height: 35px;
            padding-bottom: 20px;
            border-bottom: 1px solid #666d8287;
            .footer-title{
                display: inline-block;
            }
            .contact{
                display: inline-block;
                position: relative;
                top:11px;
                margin-left: 40px;
                span{
                    display: inline-flex;
                    line-height: 32px;
                    margin-right: 65px;
                    img{
                        margin-right: 12px;
                        box-sizing:0;
                    }
                }
                .tel{
                    font-size: 18px;
                    font-weight: 400;
                    color:#fff;
                }
                .contact_info{
                    font-size: 14px;
                    font-weight: 400;
                    color: #AFB3BF;
                }                
            }
            .contact_btn{
                display: inline-block;
                a{
                    display: inline-block;
                    width: 110px;
                    height: 34px;
                    line-height: 34px;
                    border-radius: 17px 17px 17px 17px;
                    font-size: 14px;
                    font-weight: 400;
                    text-align:center;
                }
                .contact_us{
                    color: #fff;
                    background: #FE9000;
                }
                .proposal_btn{
                    color: #FE9000;
                    border: 1px solid #FE9000;
                    margin-left:15px;
                }
            }
        }
        /* 网站服务快递跳转 */
        .block_box3{
            margin-top: 25px;
            .jump_title{
                display: inline-block;
                width: 100px;
                font-size: 14px;
                font-weight: 400;
                color: #AFB3BF;
                line-height: 20px;
            }
            .jump_box {
                margin-bottom:12px;
                .jump_list {
                    display: inline-block;
                    margin-left: 44px;
                    a {
                        font-size: 14px;
                        font-weight: 400;
                        color: #AFB3BF;
                        line-height: 20px;
                        margin-right:47px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                .more_list>a{
                    margin-right: 36px;
                }
            }
            .last_box {
                margin-bottom: 20px;
                padding-bottom:20px;
                border-bottom: 1px solid #666d8287;
            }
        }
        /* 网站信息 */
        .block_box4{
            margin-top: 25px;
            padding-bottom: 30px;
            text-align: center;
            p,a{
                font-size: 12px;
                font-weight: 400;
                color: #AFB3BF;
                line-height: 12px;
            }
            p{
                margin-bottom: 12px;
                img{
                    margin:0 10px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    /* hover 放大后还原 */
    .pulse:hover {
        animation: pulse 1s;
        -webkit-animation: pulse 1s;
    }
    @keyframes pulse {
        0% {
            transform: scaleX(1);
        }
        50% {
            transform: scale3d(1.05, 1.05, 1.05);
        }
        100% {
            transform: scaleX(1);
        }
    }

    /* 悬浮窗样式 */
    @media screen and (max-device-width:1024px) {
        .sus-content {
            width: 700px !important;
        }

        .su-content {
            margin: 0 0 !important;
        }

        .su-close {
            width: 3% !important;
        }
    }

    .suspension {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 998;
        //display:none;
        box-sizing:initial;
    }

    .sus-content {
        width: 1200px;
        margin: 0 auto;
    }

    .su-content {
        margin: 0 15%;
    }

    .su-content span {
        display: inline-block;
        width: 75%;
        padding-left: 15%;
        a>img{
        width:100%;
        height: 100%;
        margin-bottom:-8px;
        }
    }

    .su-close {
        width: 2%;
        cursor: pointer;
        position: absolute;
        margin-top:15px;
    }

    .su-show {
        visibility: hidden;
    }
}
</style>