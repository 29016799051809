import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入elementui组件库使用
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// swiper
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
import $ from 'jquery'
import {getReqAddCart} from '@/network/welfareMall_api';

import moment from 'moment'
Vue.prototype.$moment = moment

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 获取token
Vue.prototype.GetTokenFromCookie = function (name) {
  var token;
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=");
        if (arr[0] == name && arr[1] != "") {
            token = arr[1];
        }
    }
    if(token){
        token = 'Bearer ' + token;
    }
    return token;
}

// 封装跳转简易认证页面函数
Vue.prototype.goSimpleAuthPage = function (authUserID, authState) {
    var a  = document.createElement('a');
    let addressUrl = '';
    // 判断环境 打开简易认证页面（企业端共用）
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        addressUrl = 'https://ers-user.test-01.54test.cn';
    } else if (document.domain.startsWith("mall.1renshi.com")) {
        addressUrl = 'https://user.1renshi.com';
    } else {
        addressUrl = 'https://ers-user.dev-01.54test.cn';
    }
    a.href = `${addressUrl}/GeneralPage/SimpleAuth?userId=${authUserID}&authState=${authState}`;//1未认证 2认证中
    $('body').append(a);
    a.click();
    $(a).remove();
  }



// 判断官网和HR端当前环境    获取老项目基准地址
Vue.prototype.preUrlOld = function () {
  if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
      return "https://ers-user.test-01.54test.cn";
  } else if(document.domain.startsWith("mall.1renshi.com")) {
      return "https://user.1renshi.com";
  } else {
      return "https://ers-user.test-01.54test.cn";
  }
}()


// 判断官网和HR端当前环境 获取后台API接口地址
Vue.prototype.preUrlApi = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://api.ers-business.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://busapi.1renshi.com";
    } else {
        return "https://api.ers-business.dev-01.54test.cn";
    }
}()

// 判断当前环境,是否为首页地址
Vue.prototype.preUrlHome = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://ers-official.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://www.1renshi.com";
    } else {
        return "https://ers-official.test-01.54test.cn";
    }
}()


// 判断官网和HR端当前环境    获取老项目基准地址
Vue.prototype.communityUrl = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://ers-community.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://community.1renshi.com";
    } else {
        return "https://ers-community.test-01.54test.cn";
    }
}()

// 判断官网和HR端当前环境    获取老项目基准地址
Vue.prototype.apiUrl = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://api.ers-content.test-01.54test.cn/api";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://ersapi.1renshi.com/api";
    } else {
        return "https://api.ers-content.test-01.54test.cn/api";
    }
}()

// 判断当前环境,是否为HR文库地址
Vue.prototype.hrUrl = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://ers-wenku.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://wenku.1renshi.com";
    } else {
        return "https://ers-wenku.test-01.54test.cn";
    }
}()



// 事件总线
Vue.prototype.$bus = new Vue();

// 判断是否是登录状态
if(!Vue.prototype.GetTokenFromCookie('pcToken')){
  // 调用未登录时缓存中的购物车数量
  store.dispatch('getNoLoginCartNum');
}else{
  // 调用获取用户信息
  store.dispatch('getUserInfo',{_this: Vue});
}

//跳转页面，显示在顶部
router.afterEach((to,from,next)=>{
    window.scrollTo(0,0)
})

//百度统计代码
var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?4c53cc71636697c31cfaa19a14311f71";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();

//使用router的beforeEach 全局前置守卫方法在每次路由之前调用百度统计代码
router.beforeEach((to, from, next) => {
    if (_hmt) {
        if (to.path) {
            _hmt.push(['_trackPageview', to.fullPath]);
        }
    }
    next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
