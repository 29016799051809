<template>
  <div class="meal_details">
    <search-com @search-btn="searchBtn"></search-com>
    <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    <div class="details_box">
        <div class="meal_info">
            <el-carousel class="info_carousel" indicator-position="outside" arrow="nerver">
                <el-carousel-item>
                    <img :src="mealData.mealImage">
                </el-carousel-item>
            </el-carousel>
            <div class="info_box">
                <p class="info_title">{{mealData.mealName}}</p>
                <p class="info_summary">
                    <span class="info_text">单价</span>：
                    <span class="info_price">¥{{mealData.price}}</span>
                </p>
                <p class="info_summary">
                    <span class="info_text">销量</span>：
                    <span class="info_text">{{mealData.saleCount}}</span>
                </p>
                <p class="info_summary">
                    <span class="info_text">数量</span>：
                    <el-input-number class="info_number" v-model.number="mealNum" size="middle" :min="1" max="999"></el-input-number>
                </p>
                <p class="info_summary" style="margin-top:30px;">
                    <span class="info_text">服务承诺</span>：
                    <span class="info_other"><i class="info_yd"></i>机构授权<i class="info_yd"></i>未约可退</span>
                </p>
                <p class="info_btn">
                    <el-button style="color:#fff;" v-if="mealData.isActive" type="primary" @click="addCarBtn"> 加入购物车</el-button>
                    <el-button v-else type="info" plain disabled>已下架</el-button>
                </p>
            </div>
            <div class="jigou_summary">
                <img class="jigou_img" :src="mealData.hospImage" :alt="mealData.hospName"/>
                <p class="jigou_name">{{mealData.hospName}}</p>
                <p class="jigou_text"><span>机构等级：</span><span>{{mealData.hospRank}}</span></p>
                <p class="jigou_text"><span>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</span><span>{{mealData.hospAddress}}</span></p>
                <p class="jigou_text"><span>体检时间：</span><span>{{mealData.hospWorkTime}}</span></p>
            </div>
        </div>
        <el-tabs class="meal_content" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="套餐详情" name="first">
                <div class="table_box" v-if="mealData.mealContentList&&mealData.mealContentList.length">
                    <div class="table_title">检查项目({{mealData.mealContentList.length}}项)</div>
                    <div class="table_th">
                        <span class="table_td_l">检查项目</span>
                        <span class="table_td_r">检查指标意义</span>
                    </div>
                    <div class="table_tr" v-for="tableItem in mealData.mealContentList" :key="tableItem.bzzhxmbh">
                        <span class="table_td_l">{{tableItem.bzzhxmmc}}</span>
                        <span class="table_td_r">{{tableItem.bzzhxmms}}</span>
                    </div>
                </div>
                <div class="table_none" style="width:924px;" v-else></div>
                <div class="process_box">
                    <div class="process_head"><img src="~/assets/img/health/process_icon.png" /><span>购买后流程</span></div>
                    <div class="process_list">
                        <div class="process_iconinfo">
                            <img src="~/assets/img/health/process_img1.png"/>
                            <i></i>
                            <img src="~/assets/img/health/process_img2.png"/>
                            <i></i>
                            <img src="~/assets/img/health/process_img3.png"/>
                        </div>
                        <div class="process_content">
                            <p class="process_item">
                                <span class="process_title">发放体检套餐</span>
                                <span class="process_text">企业登录壹人事平台将已购买的体检套餐发放给员工</span>
                            </p>
                            <p class="process_item">
                                <span class="process_title">员工预约</span>
                                <span class="process_text">员工在壹员之家App进行预约体检</span>
                            </p>
                            <p class="process_item">
                                <span class="process_title">员工体检</span>
                                <span class="process_text">员工自行按照预约时间前往体检机构完成体检</span>
                            </p>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="体检须知" name="second">
                <div class="tijian_box">
                    <div class="tijian_liucheng">
                        <p class="tijian_title">体检流程</p>
                        <div class="liucheng_neirong">
                            <p class="liucheng_item"><img src="~/assets/img/health/tijian_img1.png"/><span>体检登记</span></p>
                            <img class="liucheng_icon" src="~/assets/img/health/tijian_icon.png"/>
                            <p class="liucheng_item"><img src="~/assets/img/health/tijian_img2.png"/><span>领取“体检引导单”</span></p>
                            <img class="liucheng_icon" src="~/assets/img/health/tijian_icon.png"/>
                            <p class="liucheng_item"><img src="~/assets/img/health/tijian_img3.png"/><span>完成体检流程</span></p>
                            <img class="liucheng_icon" src="~/assets/img/health/tijian_icon.png"/>
                            <p class="liucheng_item"><img src="~/assets/img/health/tijian_img4.png"/><span>“体检引导单”交至服务台</span></p>
                        </div>
                    </div>
                    <div class="tijian_qian">
                        <p class="tijian_title">体检前</p>
                        <p class="tijian_text">1.体检前两天请注意饮食，不吃高脂、高蛋白食物，不饮 酒，不要吃对肝、肾功能有损害的药物。</p>
                        <p class="tijian_text">2.从检查前晚上8时后避免进食和剧烈运动，12时后禁水 最好能洗个澡，保持充足睡眠。休息不好会影响血糖、 血脂、血压。</p>
                        <p class="tijian_text">3.体检前应对口腔、鼻腔、外耳道进行清洁，不清洁容易 使一些疾病漏诊。</p>
                    </div>
                    <div class="tijian_shi">
                        <p class="tijian_title">体检中</p>
                        <p class="tijian_text">1.体检当日应体检项目要求，请务必空腹、禁食、禁水停服药品及保健品。因病长期服药者可继续服药，在体检时应向医生说明（糖尿病患者除外，随身带好糖果、点心，万一出现头晕、全身冒冷汗时，请随时服用并告诉体检医生）。</p>
                        <p class="tijian_text">2.抽血后立即压迫针孔三分钟，防止出血，勿揉局部。</p>
                        <p class="tijian_text">3.女性的子宫附件B超需待膀胱充盈后检查，请尽量留住晨尿。建议先做子宫附件B超，再做尿常规、妇科检查。</p>
                        <p class="tijian_text">4.妇科检查项目，检查对象为已婚女性。检前请排空小便。</p>
                        <p class="tijian_text">5.女性在月经期内请不要留取尿液标本及妇检，月经期后再作检查。</p>
                        <p class="tijian_text">6.女性妊娠期间体检不要做X线检查。</p>
                        <p class="tijian_text">7.对准备生育的女性可自行决定是否参加X线检查；（即胸部放射检查）。</p>
                        <p class="tijian_text">8.请带齐有关健康资料、病历等（如曾经住院或动过手术，请带病历和相关资料）。</p>
                        <p class="tijian_text">9.不要化妆，装饰会影响医生对疾病的判断.</p>
                    </div>
                </div>
                <div class="process_box">
                    <div class="process_head"><img src="~/assets/img/health/process_icon.png" /><span>购买后流程</span></div>
                    <div class="process_list">
                        <div class="process_iconinfo">
                            <img src="~/assets/img/health/process_img1.png"/>
                            <i></i>
                            <img src="~/assets/img/health/process_img2.png"/>
                            <i></i>
                            <img src="~/assets/img/health/process_img3.png"/>
                        </div>
                        <div class="process_content">
                            <p class="process_item">
                                <span class="process_title">发放体检套餐</span>
                                <span class="process_text">企业登录壹人事平台将已购买的体检套餐发放给员工</span>
                            </p>
                            <p class="process_item">
                                <span class="process_title">员工预约</span>
                                <span class="process_text">员工在壹员之家App进行预约体检</span>
                            </p>
                            <p class="process_item">
                                <span class="process_title">员工体检</span>
                                <span class="process_text">员工自行按照预约时间前往体检机构完成体检</span>
                            </p>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>
<script>
import SearchCom from '@/components/welfareMallComp/SearchCom';
import WelfareBreadcrumb from "@/components/welfareMallComp/WelfareBreadcrumb";
import { getHealthMealInfo,AddHealthCart } from "network/welfareMall_api.js";
import { mapState, mapMutations} from 'vuex';
export default {
  name: "TijianMealDetails",
  components: {
    SearchCom,
    WelfareBreadcrumb,
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: "福利商城",
          to: "/welfareHome",
        },
        {
          name: "员工体检",
        },
      ],
      activeName:"first",
      mealData:{},
      mealNum:"",
    };
  },
  computed:{
    // 映射
    ...mapState(['useInfo','cartNum'])
  },
  created(){
    let mealId=this.$route.query.mealId;
    if(mealId){
        this.getHealthMealData(mealId);
    }
  },
  methods:{
    // 映射mapMutations里的方法
    ...mapMutations(['saveCartNum']),
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    handleClick(tab, event) {
        //console.log(tab, event);
    },
    async getHealthMealData(mealId){
      let _this = this;
      let res = await getHealthMealInfo(mealId);
      if (res.isSuccess) {
        // console.log(res.data)
        _this.mealData = res.data;
        _this.mealData.price = _this.mealData.price.toFixed(2);
      }
    },
   async addCarBtn(){
    let myCartList = [];
    let mealObj = {};
    mealObj.mealID = this.mealData.mealId;
    mealObj.mealName = this.mealData.mealName;
    mealObj.mealPrice = this.mealData.price;
    mealObj.mealUrl = this.mealData.mealImage;
    mealObj.mealCount = this.mealNum;
    mealObj.amount = this.mealData.price*this.mealNum;
    mealObj.orderType = 4;
    mealObj.festivalName = '员工体检';
    mealObj.isActive = true;// 初始化当前套餐显示为有效
    // 判断是否已在缓存中添加有购物车
    if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
        myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        // 此时是未登录状态添加进缓存
        // 判断是否有该礼包  无 则push缓存 反之
        var boo = myCartList.some((item)=>{
          return item.mealID === mealObj.mealID;
        })
        if(!boo){
          myCartList.push(mealObj);
        }else{
          // 循环判断缓存中是否有改同样礼包
          myCartList.forEach((item)=>{
            // 如果有 数量相加
            if(item.mealID === mealObj.mealID){
              item.mealCount += mealObj.mealCount;
              item.amount = (item.mealCount * item.mealPrice);
            }
          })
        }
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 判断是否是登录状态
      }else if(!this.useInfo.privateUserID){
        myCartList.push(mealObj);
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 已登录状态
      }else if(this.useInfo.privateUserID){
        let data ={
            MealID:mealObj.mealID,
            MealCount:mealObj.mealCount 
        }
        const params=[data];
        // 加入购物车调登录的加入接口
        var res = await AddHealthCart(params);
        if(res.isSuccess){
          this.$message.success(res.msg);
          // 在vuex中调用获取当前登录用户的购物车数量
          this.$store.dispatch('getLoginCartNum');
        }else{
          this.$message.error(res.msg);
        }
      }
   }
  }
};
</script>
<style lang="less" scoped>
.meal_details{
    width: 1200px;
    margin: 0 auto;
    .meal_info{
        display: flex;
        margin-top: 30px;
        .info_carousel{
            width: 400px;
            .el-carousel__item{
                width: 100%;
                height: 300px;
                border-radius: 10px;
                background: #DFDFDF;
                img{
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                }
            }
            /deep/.el-carousel__arrow {
                display: none !important;
            }
            /deep/.el-carousel__indicators{
                .el-carousel__indicator .el-carousel__button{
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background: #CFDDFF;
                    border-radius: 4px;
                    opacity: 1;
                }
                .is-active .el-carousel__button{
                    background: #4C80FA;
                }
            }
        }
        .info_box{
            margin-left: 20px;
            display: inline-block;
            width: 526px;
            border-right: 1px solid #E8E8E8;
            .info_title{
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
            }
            .info_summary{
                margin-bottom: 18px;
                display: flex;
                align-items: center;
                .info_text{
                    width:56px;
                    margin-right: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: justify;
                    text-align-last:justify;
                }
                .info_price{
                    font-weight: bold;
                    font-size: 20px;
                    color: #F64F42;
                }
                .info_number{
                    width: 140px;
                }
                .info_other{
                    display: inline-flex;
                    align-items: center;
                    .info_yd{
                        margin-right: 7px;
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background: #4C80FA;
                        border-radius:50%;
                        &:nth-child(2){
                            margin-left: 20px;
                        }
                    }
                }
            }
            .info_btn .el-button{
                border-radius: 20px;
            }
        }
        .jigou_summary{
            margin-left: 20px;
            display: inline-block;
            width: 213px;
            .jigou_img{
                width: 100%;
                height: 120px;
                background-size:cover;
            }
            .jigou_name{
                margin-top: 15px;
                font-size: 16px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .jigou_text{
                margin-top: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #E8E8E8;
                &:last-child{
                    padding-bottom: 0;
                    border:none;
                }
                span{
                    font-size: 14px;
                    color: #999999;
                }
                span:nth-child(1){
                    color:#333333
                }
            }
        }
    }
    .meal_content{
        margin-top: 30px;
        margin-bottom: 40px;
        /deep/.el-tabs__item{
            font-size: 16px;
            color: #999;
        }
        /deep/.el-tabs__item.is-active{
            color: #409eff;
        }
        /deep/.el-tab-pane{
            display: flex;
            margin-top: 15px;
        }
        .table_box{
            display: inline-block;
            width: 100%;
            border:1px solid #E6E6E6;
            border-bottom:none;
            .table_title{
                padding:13px 0;
                font-weight: 400;
                font-size: 16px;
                color: #1B1B1B;
                text-align: center;
                background:#E6E6E6;
            }
            .table_th,.table_tr{
                display: flex;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                text-align: center;
                border-bottom:1px solid #E6E6E6;
                .table_td_l{
                    width: 30%;
                    padding:13px 30px;
                    border-right: 1px solid #E6E6E6;

                }
                .table_td_r{
                    width: 70%;
                    padding:13px 25px 13px 30px;
                }
            }
            .table_tr{
                text-align: left;
            }
        }
        .tijian_box{
            display: inline-block;
            width: 78%;
            .tijian_title{
                font-weight: bold;
                font-size: 16px;
                color: #333333;
                line-height: 20px;
            }
            .tijian_liucheng .liucheng_neirong{
                position:relative;
                width: 90%;
                margin:40px auto 0;                
                p.liucheng_item{
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 86px;
                        height: 86px;
                        background-size:cover;
                    }
                    span{
                        margin-top: 10px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #333333;
                    }
                    &:nth-child(3){
                        margin-left: 115px;
                    }
                    &:nth-child(5){
                        margin-left: 110px;
                    }
                    &:nth-child(7){
                        margin-left: 87px;
                    }
                }
                .liucheng_icon{
                    margin-top: 37px;
                    display: inline-block;
                    width: 145px;
                    height: 13px;
                    background-size: cover;
                    position: absolute;
                    &:nth-child(4){
                        margin-left: -30px;
                    }
                    &:nth-child(6){
                        margin-left: -5px;
                    }
                }
            }
            .tijian_qian,.tijian_shi{
                margin-top: 40px;
                .tijian_text{
                    margin-top: 20px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    line-height: 24px;
                    letter-spacing: 1px;
                }
            }
        }
        .process_box{
            margin-left: 20px;
            display: inline-block;
            padding:20px;
            width: auto;
            height: max-content;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            .process_head{
                display: flex;
                align-items: center;
                padding-bottom: 12px;
                border-bottom:1px solid #E8E8E8;
                img{
                    width: 18px;
                    height: 18px;
                    background-size: cover;
                }
                span{
                    margin-left: 9px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #333333;
                    line-height: 18px;
                }
            }
            .process_list{
                margin-top: 24px;
                display: flex;
                .process_iconinfo{
                    width: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 40px;
                        height: 40px;
                        background-size: cover;
                    }
                    i{
                        display: block;
                        width: 4px;
                        height: 78px;
                        background: #EAEEFF;
                    }
                }
                .process_item{
                    margin-left: 6px;
                    width: 168px;
                    &:nth-child(2){
                        margin-top: 50px;
                    }
                    &:nth-child(3){
                        margin-top: 52px;
                    }
                    .process_title{
                        display: block;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        line-height: 20px;
                    }
                    .process_text{
                        margin-top: 6px;
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
</style>