<template>
  <div class="organization_box">
    <div class="search-box">
      <search-com @search-btn="searchBtn" ref="searchCom"></search-com>
    </div>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <div class="city_box">
      <div class="city_now">
        <img class="city_dw" src="~/assets/img/health/icon_dw.png" alt="定位图标"/>
        <span class="city_text">当前城市：<b>{{activeCity}}</b></span>
        <span class="city_btn"><i class="el-icon-sort"  @click="openCheckCity"></i>切换城市</span>
      </div>
      <div class="city_list_box" v-if="showCityBox">
        <i class="el-icon-close" @click="showCityBox = false"></i>
        <el-input class="city_input" v-model="inputValue" placeholder="请输入城市名" clearable @input="selectInput()" @focus="searchFocus()"></el-input>
        <div class="result_box" v-if="inputValue&&inputValue.length">
          <div class="city_select_box" v-if="selectList&&selectList.length">
            <div class="select_result" v-for="selectItem in selectList" :key="selectItem.cityCode" @click="clickInput(selectItem.cityName)">{{selectItem.cityName}}</div>
          </div>
          <div class="city_select_none" v-else>未查询到相关内容</div>
        </div>
        <city-box :isShow="showCityBox" @data-cityname="getCityName"></city-box>
      </div>
    </div>
    <div class="choose_box">
      <div class="choose_item">
        <span class="choose_text">套餐类别</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('MealType','')">
            <span :class="chooseList.MealType==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" 
            v-for="taocanItem in taocanList" 
            :key="taocanItem.typeId" 
            @click="selectOption('MealType', taocanItem.typeId)">
            <span :class="chooseList.MealType==taocanItem.typeId?'list_item choose_active':'list_item'">      
              {{taocanItem.typeName}}
            </span>
          </div>
          <div class="list_more" v-if="taocanList.length>9">
            <span>更多<i :class="taocanShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">医院等级</span>：
       <div class="list_box">
          <div class="list_item_box" @click="selectOption('HospRank','')">
            <span :class="chooseList.HospRank==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" 
            v-for="dengjiItem,dengjiIndex in dengjiList" 
            :key="dengjiIndex" 
            @click="selectOption('HospRank',dengjiItem)">
            <span :class="chooseList.HospRank==dengjiItem?'list_item choose_active':'list_item'">{{dengjiItem}}</span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">区域</span>：
         <div class="list_box">
          <div class="list_item_box" @click="selectOption('AreaName','')">
            <span :class="chooseList.AreaName==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" 
            v-for="areaItem in areaList" 
            :key="areaItem.areaCode"  
            @click="selectOption('AreaName',areaItem.areaName)">
            <span :class="chooseList.AreaName==areaItem.areaName?'list_item choose_active':'list_item'">{{areaItem.areaName}}</span>
          </div>
          <div class="list_item_box" v-if="areaList.length>9">
            <span>更多<i :class="areaShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">机构服务</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('Service','')">
            <span :class="chooseList.Service==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" 
            v-for="serviceItem,serviceIndex in serviceList" 
            :key="serviceIndex"
            @click="selectOption('Service',serviceItem)">
            <span :class="chooseList.Service == serviceItem?'list_item choose_active':'list_item'">{{serviceItem}}</span>
          </div>
          <div class="list_item_box" v-if="serviceList.length>9">
            <span>更多<i :class="serviceShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="list-box" v-if="tableList.length > 0">
      <div class="list-item" v-for="item in tableList" :key="item.hospId">
        <div class="left-box">
          <div class="img-box" @click="goOrgDtlBtn(item.hospId)">
            <img :src="item.hospImage" alt="" />
          </div>
          <div class="info-box">
            <div class="name" @click="goOrgDtlBtn(item.hospId)">{{item.hospName}}</div>
            <div class="gray-box">
              <div class="gray-item">
                  <img class="icon-box icon-box-hospital" src="~/assets/img/health/icon-hospital.png" alt="">
                <div class="text">{{item.hospRank}}</div>
              </div>
              <div class="gray-item">
                  <img class="icon-box icon-box-address" src="~/assets/img/health/icon-address.png" alt="">
                <div class="text">{{item.hospAddress}}</div>
              </div>
              <div class="gray-item">
                  <img class="icon-box icon-box-time" src="~/assets/img/health/icon-time.png" alt="">
                <div class="text">{{item.hospWorkTime}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="num-box">
            体检套餐：<span class="black-text">{{item.mealCount}}个</span>
          </div>
          <div class="price-box">
            ¥ <span class="price-text">{{item.minPrice}}</span> 起
          </div>
          <div class="btn-box" @click="goOrgDtlBtn(item.hospId)">
            查看详情
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[20, 30, 40, 50, 60]"
          layout="prev, pager,next,sizes,jumper,total"
          :page-size="Params.Limit"
          background
          :total="Params.TotalCount"
          >
        </el-pagination>
      </div>
    </div>
    <div class="empty-box" v-else>
      <img src="~/assets/img/health/empty.png" alt="">
    </div>
  </div>
</template>
<script>
import WelfareBreadcrumb from "@/components/welfareMallComp/WelfareBreadcrumb";
import SearchCom from "@/components/welfareMallComp/SearchCom";
import cityBox from "@/components/common/city";
import {getAreaList, getServiceList,getDataType, getHospitalList,getCityList } from "network/welfareMall_api.js";
export default {
  name: "OrganizationList",
  components: {
    WelfareBreadcrumb,
    SearchCom,
    cityBox,
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工体检',
        }
      ],
      showCityBox: false,
      activeCity:'北京',
      searchTimer:null,
      inputValue:'',
      selectList:[],
      chooseList:{
        CityName: '',
        AreaName: '',
        MealType: '',
        HospRank: '',
        Service: '',
      },
      taocanList:[], // 套餐类别
      dengjiList:[
        '公立三甲','公立医院','民营医院','专业体检中心',
      ],
      areaList:[], // 区域
      serviceList:[], // 机构服务
      Params:{
        PageIndex:1,
        Limit:10,
        TotalCount:0,
      },
      tableList:[],
    };
  },
  created(){
    this.getTableList();
    this.getArea('北京');
    this.getService();
    this.getType();
  },
  methods:{
    selectOption(type,value){
      this.chooseList[type] = value;
      this.getTableList();
    },
    // 获取类型
    async getType(){
      let res = await getDataType(1);
      if(res.isSuccess){
        this.taocanList = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    // 获取服务
    async getService(){
      let res = await getServiceList();
      if(res.isSuccess){
        this.serviceList = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    // 获取区域
    async getArea(city){
      let res = await getAreaList(city);
      console.log('获取区域',res)
      if(res.isSuccess){
        this.areaList = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    async getTableList(){
      let data = {...this.chooseList, ...this.Params};
      data.CityName = this.activeCity;
      delete data.TotalCount
      let res = await getHospitalList(data);
      if(res.isSuccess){
        this.tableList = res.data;
        this.Params.TotalCount = res.count;
      }else{
        this.$message.error(res.msg)
      }
    },
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    // 切换城市
    openCheckCity(){
      this.showCityBox=true;
      this.inputValue ='';
      this.chooseList.AreaName = '';
      this.selectList=[];
    },
    debounce(fn, delay = 100) {
      clearTimeout(this.searchTimer)
      this.searchTimer= setTimeout(fn, delay)
    },
    // 搜索输入框focus事件
    searchFocus() {
      if(this.inputValue&&this.inputValue.trim() !== '') {
        this.debounce(this.selectInput);
      }
    },
    // 搜索输入框input事件
    async selectInput(){
      let res = await getCityList(this.inputValue);
      if(res.isSuccess){
        let cityList = res.data;
        const outputData = cityList.reduce((acc, item) => {
          // 将当前项的 cityList 合并到累积器数组中
          return acc.concat(item.cityList.map(city => ({
            cityCode: city.cityCode,
            cityName: city.cityName
          })));
        }, []);
        this.selectList = outputData;
      }
    },
    clickInput(city){
      this.activeCity = city;
      this.getCityName(city);
      this.getTableList();
    },
    //根据城市获取区域
    getCityName(data){
      this.activeCity = data;
      this.getArea(data);
      this.getTableList();
      this.showCityBox=false;
    },
    //选择区域
    selectOption(type,value){
      this.chooseList[type] = value;
      this.Params.PageIndex = 1;
      this.getTableList();
    },
    // 详情
    goOrgDtlBtn(hospId){
      this.$router.push({
        name: 'OrganizationDetails',
        query:{
          hospId: hospId
        }
      })
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      this.getTableList();
    },
    handleSizeChange(val) {
      this.Params.Limit = val;
      this.getTableList();
    },
  }
};
</script>
<style lang="less" scoped>
  .organization_box{
    width: 1200px;
    margin: 0 auto;
    .city_box{
      margin-top: 30px;
      .city_now{
        display: flex;
        align-items: center;
        .city_dw{
          width: 15px;
          height: 18px;
          background-size: cover;
        }
        .city_text{
          margin-left: 8px;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          b{
            font-weight: 400;
            font-size: 14px;
            color: #4C80FA;
          }
        }
        .city_btn{
          cursor: pointer;
          margin-left: 10px;        
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          border-left: 1px solid #999;
          i{
            margin-left: 10px;
            margin-right: 5px;
            transform: rotate(90deg);          
          }
        }
      }
      .city_list_box{
        margin:10px 0 0 125px;
        padding:10px 20px 20px;
        position: absolute;
        z-index: 1;
        width: 433px;
        background: #FFFFFF;
        border-radius: 12px;
        border: 1px solid #DCDCDC;
        .el-icon-close{
          position:absolute;
          top:10px;
          right: 10px;
          font-size: 16px;
        }
        .city_input{
          margin-top: 26px;
        }
        .result_box{
          position: absolute;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #ECECEC;
          .city_select_box .select_result{
            font-size: 14px;
            color:#333;
            line-height: 26px;
            padding: 5px 20px;
            &:hover{
              cursor: pointer;
              color: #fff;
              background: #4C80FA;
            }
          }
          .city_select_none{
            font-size: 14px;
            color: #999;
            line-height: 26px;
            padding: 5px 20px;
          }
        }
      }
    }
    .choose_box{
      margin-top: 30px;
      .choose_item{
        display: flex;
        margin-bottom: 10px;
        .choose_text{
          display: inline-block;
          width: 56px;
          height: 26px;
          line-height: 26px;
          font-weight: normal;
          font-size: 14px;
          color: #333333;
          text-align: justify;
          text-align-last: justify;
        }
        .list_box{
          margin-left: 10px;
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .list_item_box{
            cursor: pointer;
            margin-bottom: 10px;
            flex:0 0 106px;
            display: inline-block;
            height: 26px;
            line-height: 24px;
          }
          .list_item{
            max-width: 104px;
            display: inline-block;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:nth-child(1){
              margin-left: 0;
            }
          }
          .choose_active{
            padding:0 10px;
            font-size: 14px;
            color: #4C80FA;
            border:1px solid #4C80FA;
            border-radius: 5px;
          }
          .list_more{
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;          
            font-size: 14px;
            color: #333333;
          
          }
        }
      }
    }
    // 列表
    .list-box{
      padding-bottom: 65px;
      .list-item{
        display: flex;
        justify-content: space-between;
        height: 155px;
        margin-bottom: 52px;
        .left-box{
          display: flex;
          .img-box{
            width: 216px;
            height: 155px;
            margin-right: 16px;
            border-radius: 10px 10px 10px 10px;
            overflow: hidden;
            img{
              cursor: pointer;
              width: 100%;
              height: 100%;
            }
          }
          .info-box{
            box-sizing: border-box;
            padding: 12px 0;
            .name{
              cursor: pointer;
              font-weight: 700;
              font-size: 16px;
              color: #333333;
              line-height: 21px;
              margin-bottom: 30px;
            }
            .gray-box{
              .gray-item{
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #666666;
                line-height: 21px;
                margin-bottom: 10px;
                .icon-box{
                  margin-right: 10px;
                  object-fit:contain;
                }
                .icon-box-hospital{
                  width: 13px;
                  height: 13px;
                }
                .icon-box-address{
                  width: 12px;
                  height: 16px;
                }
                .icon-box-time{
                  width: 14px;
                  height: 14px;
                }
                &:last-child{
                  cursor: pointer;
                }
              }
            }
          }
        }
        .right-box{
          padding: 12px 0;
          // text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .num-box{
            font-size: 16px;
            color: #999999;
            line-height: 21px;
            margin-bottom: 30px;
            .black-text{
              color: #333333;
            }
          }
          .price-box{
            font-size: 12px;
            color: #EB3B3B;
            line-height: 21px;
            margin-bottom: 10px;
            .price-text{
              font-size: 24px;
              font-weight: 700;
              padding: 0 2px;
            }
          }
          .btn-box{
            width: 86px;
            height: 32px;
            background: #0078FE;
            border-radius: 4px 4px 4px 4px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
    // 缺省图
    .empty-box{
      padding: 30px 0 65px;
      display: flex;
      justify-content: center;
      img{
        width: 270px;
        height: 270px;
      }
    }
  }
</style>