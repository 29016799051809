<template>
    <!-- 右侧侧边栏 -->
    <aside class="layout_aside">
        <a class="aside_item" id="onlineConsultation">
            <i class="aside_item_i1"></i>
            <span>在线咨询</span>
        </a>
        <div class="online_consultation">
            <div class="online_content">
                <div class="time_text online_word">工作时间</div>
                <div class="online_work online_time">
                    <img src="~/assets/img/header/online_time.png" />
                    <span>工作日 9：00-18：00</span>
                </div>
                <div class="phone_text online_word">客服电话</div>
                <div class="online_work online_phone">
                    <img src="~/assets/img/header/online_worker.png" />
                    <span>400-189-1900</span>
                </div>
                <div class="consultation_tips">非工作时间欢迎在线留言，客服上线后将第一时间为您处理</div>
                <a class="consultation_btn" href="javascript:void(0)" onclick="javascript:window.open('https://www.echatsoft.com/visitor/pc/chat.html?companyId=12203&echatTag=website','','width=1000,height=700,top=200,left=200')" rel="nofollow"></a>
                <div class="qywx_box">
                    <img src="~/assets/img/footer/icon_qywx.png" alt="企业微信"/>
                    <span>业务咨询，加企业微信沟通</span>
                </div>
            </div>
            <div class="online_none"></div>
        </div>
        <a class="aside_item" id="smallTool">
            <i class="aside_item_i2"></i>
            <span>计算工具</span>
        </a>
        <div class="small_tool">
           <div class="tool_box">
            <a :href="jumpLink+'/shebaocesuan.html'" target="_blank" rel="nofollow">
                <i class="tool_icon1"></i>
                <span>五险一金计算器</span>
            </a>
            <a :href="jumpLink+'/yanglaojinjisuanqi.html'" target="_blank" rel="nofollow">
                <i class="tool_icon2"></i>
                <span>养老金计算器</span>
            </a>
            <a :href="jumpLink+'/geshuijisuanqi.html'" target="_blank" rel="nofollow">
                <i class="tool_icon3"></i>
                <span>工资个税计算器</span>
            </a>
            <a :href="jumpLink+'/yanchituixiujisuanqi.html'" target="_blank" rel="nofollow">
                <i class="tool_icon3"></i>
                <span>延迟退休计算器</span>
            </a>
           </div>
        </div>
        <a class="aside_item" :href="jumpLink+'/aboutus.html'">
            <i class="aside_item_i3"></i>
            <span>关于我们</span>
        </a>
        <a class="aside_item" id="followUs">
            <i class="aside_item_i4"></i>
            <span>关注我们</span>
        </a>
        <div class="follow_us">
           <div class="follow_content">
             <div class="follow_title"></div>
             <div class="follow_qrcode">
                <div class="follow_item">
                    <img src="~/assets/img/footer/fl-qrCode.jpg" alt="加入HR交流群" />
                    <span>加入HR交流群</span>
                </div>
                <div class="follow_item">
                    <img src="~/assets/img/footer/gzh-qrCode.png" alt="壹人事企业服务" />
                    <span>壹人事企业服务</span>
                </div>
                <div class="follow_item">
                    <img src="~/assets/img/footer/kf-qrCode.png" alt="壹人事员工服务" />
                    <span>壹人事员工服务</span>
                </div>
            </div>
           </div>
        </div>
        <a class="aside_item" :href="jumpLink+'/noviceschool.html'">
            <i class="aside_item_i5"></i>
            <span>新手学堂</span>
        </a>
        <a class="aside_item" :href="jumpLink+'/helpCenter.html'">
            <i class="aside_item_i6"></i>
            <span>帮助中心</span>
        </a>
        <a class="aside_item page_up"></a>

        <div class="page_view" style="display:none;">
            <img src="~/assets/img/footer/icon_countdown.png" />
            <span id="Countdown">15s</span>
        </div>
    </aside>
</template>
<script>
import $ from "jquery";
import {getSendScore} from 'network/welfareMall_api.js';
import GetTokenFromCookie from 'network/request.js';
export default {
   data() {
        return {
            jumpLink: this.preUrlHome,
            jumpLoginLink:this.preUrlOld,
            communityLink:this.communityUrl,
        };
    },
    mounted(){
        //在线咨询
        $('#onlineConsultation').mouseenter(function () {
            $('#onlineConsultation').addClass("aside_item_active1");
            $('.online_consultation').css('display','flex');
        })
        $('#onlineConsultation').mouseleave(function () {
            $('#onlineConsultation').removeClass("aside_item_active1");
            $('.online_consultation').hide();
        })
        $('.online_consultation').mouseenter(function () {
            $('#onlineConsultation').addClass("aside_item_active1");
            $('.online_consultation').css('display','flex');;
        })
        $('.online_consultation').mouseleave(function () {
            $('#onlineConsultation').removeClass("aside_item_active1");
            $('.online_consultation').hide();
        })
        //计算工具
        $('#smallTool').mouseenter(function () {
            $('#smallTool').addClass("aside_item_active2");
            $('.small_tool').show();
        })
        $('#smallTool').mouseleave(function () {
            $('#smallTool').removeClass("aside_item_active2");
            $('.small_tool').hide();
        })
        $('.small_tool').mouseenter(function () {
            $('.small_tool').show();
            $('#smallTool').addClass("aside_item_active2");
        })
        $('.small_tool').mouseleave(function () {
            $('.small_tool').hide();
            $('#smallTool').removeClass("aside_item_active2");
        })
        //关于我们
        $('#followUs').mouseenter(function () {
            $('#followUs').addClass("aside_item_active4");
            $('.follow_us').show();
        })
        $('#followUs').mouseleave(function () {
            $('#followUs').removeClass("aside_item_active4");
            $('.follow_us').hide();
        })
        $('.follow_us').mouseenter(function () {
            $('.follow_us').show();
            $('#followUs').addClass("aside_item_active4");
        })
        $('.follow_us').mouseleave(function () {
            $('.follow_us').hide();
            $('#followUs').removeClass("aside_item_active4");
        })
        // 返回顶部
        $('.page_up').click(function () {
            $('body,html').animate({ scrollTop: 0 }, 1000);
        })
        let _this = this;
        $(document).ready(function () {
            // document.cookie = "taskJumpId=T08; path=/;";
            let taskJumpId = _this.GetTokenFromCookie("taskJumpId");
            if(taskJumpId){
                taskJumpId = taskJumpId.substring(taskJumpId.indexOf(" ")+1,taskJumpId.length);
                _this.pageCountdown(taskJumpId);
            }
        });
    },
    methods:{
        //设置倒计时
        pageCountdown(taskId) {
            let _this = this;
            $('.page_view').show();
            var countdown = 15;
            var countdownElement = $('#Countdown');
            var countdownInterval = setInterval(async function () {
                countdown--;
                countdownElement.text(countdown + 's');
                if (countdown <= 0) {
                    // 这里可以放置倒计时结束后要执行的代码  
                    clearInterval(countdownInterval);
                    let res = await getSendScore(taskId);
                    if (res.data) {
                        countdownElement.css({ 'font-size': '12px', 'color': '#3c3c3c' });
                        countdownElement.html('任务完成<br/>点击返回');
                        window.addEventListener('unload', function () {
                            document.cookie = "taskJumpId=" + taskId + " ; path=/";
                        });
                    }else{
                        countdownElement.hide();
                    }
                } 
                else {
                    // 监听 只要跳出当前就结束
                    window.addEventListener('unload', function () {
                        document.cookie = "taskJumpId==; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    });
                }
                if (countdown == 0) {
                    // console.log(countdown)
                    $('.page_view').click(function () {
                        window.location.href= _this.jumpLink+'/UserCenter/UserTask';
                    })
                }
            }, 1000); // 每秒更新一次  
        },
    }
}
</script>
<style lang="less" scoped>
/* 侧边栏工具 */
.layout_aside {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    background: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0,27,58,0.08);
    border-radius:5px;
}
.layout_aside .aside_item {
    width: 70px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.layout_aside .aside_item:first-child {
    border-radius: 5px 5px 0 0;
}
.layout_aside .aside_item span {
    font-size: 12px;
    font-weight: 400;
    color: #3C3C3C;
    line-height: 18px;
    letter-spacing: 1px;
    padding-bottom:10px;
    border-bottom: 1px solid #E2E2E2;
}
.layout_aside .aside_item i {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top:10px;
    margin-bottom: 8px;
}
.layout_aside .aside_item .aside_item_i1 {
    background-image: url('../assets/img/header/aside_item_i1.png');
}
.layout_aside .aside_item_active1 {
    background: #4C80FA;
}
.layout_aside .aside_item_active1 span {
    color: #fff;
    border: 1px solid #4c80fa;
}
.layout_aside .aside_item_active1 .aside_item_i1 {
    background-image: url('../assets/img/header/aside_item_i1_active.png');
}

.layout_aside .aside_item .aside_item_i2 {
    background-image: url('../assets/img/header/aside_item_i2.png');
}
.layout_aside .aside_item_active2 {
    background: #4C80FA;
}
.layout_aside .aside_item_active2 span {
    color: #fff;
    border: 1px solid #4c80fa;
}
.layout_aside .aside_item_active2 .aside_item_i2 {
    background-image: url('../assets/img/header/aside_item_i2_active.png');
}

.layout_aside .aside_item .aside_item_i3 {
    background-image: url('../assets/img/header/aside_item_i3.png');
}
.layout_aside .aside_item:hover .aside_item_i3 {
    background-image: url('../assets/img/header/aside_item_i3_active.png');
}

.layout_aside .aside_item .aside_item_i4 {
    background-image: url('../assets/img/header/aside_item_i4.png');
}
.layout_aside .aside_item_active4 {
    background: #4C80FA;
}
.layout_aside .aside_item_active4 span {
    color: #fff;
    border: 1px solid #4c80fa;
}
.layout_aside .aside_item_active4 .aside_item_i4 {
    background-image: url('../assets/img/header/aside_item_i4_active.png');
}

.layout_aside .aside_item .aside_item_i5 {
    background-image: url('../assets/img/header/aside_item_i5.png');
}
.layout_aside .aside_item:hover .aside_item_i5 {
    background-image: url('../assets/img/header/aside_item_i5_active.png');
}
.layout_aside .aside_item .aside_item_i6 {
    background-image: url('../assets/img/header/aside_item_i6.png');
}
.layout_aside .aside_item:hover .aside_item_i6 {
    background-image: url('../assets/img/header/aside_item_i6_active.png');
}
.layout_aside .aside_item:hover{
    background:#4C80FA;
}
.layout_aside .aside_item:hover span{
    color:#fff;
    border:1px solid #4c80fa;
}
/* 返回顶部 */
.layout_aside .page_up {
    width: 70px;
    height: 46px !important;
    border-radius: 0 0 5px 5px;
    background: #fff url(../assets/img/header/pageup_icon.png) no-repeat center;
}
.layout_aside .page_up:hover {
    cursor: pointer;
    background: #4C80FA url(../assets/img/header/pageup_icon_active.png) no-repeat center;
}
/* 在线咨询 */
.online_consultation {
    display: none;
    width: 225px;
    position: absolute;
    right: 70px;
    top: -15px;
}
.online_consultation .online_content {
    display: block;
    padding: 15px;
    border-radius: 5px;
    background: #5D5E5D;
}
.online_consultation .online_none {
    width: 12px;
    background: #ffffff00;
}
.online_consultation .online_work {
    margin-top: 10px;
    padding-left: 45px;
    margin-bottom:15px;
}
.online_consultation .online_word {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}
.online_consultation .phone_text {
    margin-top: 15px;
}
.online_consultation .online_work img {
    width: 30px;
    position: absolute;
    left: 20px;
}
.online_consultation .online_work span {
    padding: 0;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
}
.online_consultation .consultation_tips {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #fff;
    padding-top: 10px;
    border-top: 1px solid #ececec5e;
    border-top-style: dashed;
}
.online_consultation .consultation_btn {
    display:block;
    width: 152px;
    height: 30px;
    background: url(../assets/img/header/online-serve.png) no-repeat center center;
    margin-top: 12px;
    margin-left: 15px;
    border: 0;
}
.online_consultation .qywx_box {
    text-align: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ececec5e;
    border-top-style: dashed;
}
.online_consultation .qywx_box img{
    display:block;
    width:132px;
    height:132px;
    margin:0 auto;
    background-size:cover;
    border-radius:10px;
}
.online_consultation .qywx_box span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    line-height: 18px;
    margin-top: 6px;
}
/* 计算工具 */
.small_tool {
    display: none;   
    position: absolute;
    right: 70px;
    top: 0;
    padding-right:12px;
    padding-bottom:17px;
}
.small_tool .tool_box{
    display:inline-block;
    width: 96px;
    background: #5D5E5D;
    border-radius: 5px;
}
.small_tool a {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:17px;
}
.small_tool a span {
    font-size: 11px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
}
.small_tool a > i {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom:6px;
}
.small_tool a .tool_icon1 {
    margin-top:19px;
    background-image: url('../assets/img/header/shebao.png');
}
.small_tool a .tool_icon2 {
    background-image: url('../assets/img/header/yanglao.png');
}
.small_tool a .tool_icon3 {
    background-image: url('../assets/img/header/geshui.png');
}
.small_tool a .tool_icon4 {
    background-image: url('../assets/img/header/tuixiu.png');
}
/* 关注我们 */
.follow_us {
    display: none;
    position: absolute;
    right: 70px;
    top: 165px;
    padding-right:10px;
}
.follow_us .follow_content {
    padding:15px 20px 0;
    display:block;
    width:408px;
    background: #5D5E5D;
    border-radius: 5px;
}
.follow_us .follow_title {
    display:block;
    width:116px;
    height:23px;
    margin:0 auto;
    margin-top:15px;
    background-image: url('../assets/img/header/follow_title.png');
}
.follow_us .follow_qrcode {
    display: inline-block;
    margin-top: 20px;
}
.follow_us .follow_qrcode .follow_item {
    display: flex;
    width: 112px;
    float: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.follow_us .follow_qrcode .follow_item:nth-child(2n) {
   margin: 0 15px;
} 
.follow_us .follow_qrcode .follow_item img {
    display: inline-block;
    width: 112px;
    height: 112px;
    background:#fff;
    background-size: 100%;
    border-radius:5px;
}
.follow_us .follow_qrcode .follow_item span {
    font-size: 11px;
    font-weight: 400;
    color: #E9E9E9;
    line-height: 28px;
}
/* 倒计时 样式 */
.page_view {
    cursor:pointer;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 86px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 0px 5px 1px rgba(0,27,58,0.08);
    border-radius: 5px;
    z-index: 999;
}
.page_view img{
   display:inline-block;
   width:30px;
   height:30px;
   background-size:cover;
}
.page_view span {
    font-size: 14px;
    font-weight: 400;
    color: #4C80FA;
    margin-top:6px;
}
</style>