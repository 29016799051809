import Vue from 'vue'
import Vuex from 'vuex'
import $ from 'jquery';
import {getReqUserInfo, getReqCartNum, getReqAddCart,getReqMyCart,getHealthCart ,getHealthReqAddCart} from '@/network/welfareMall_api'
import {Message} from 'element-ui'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 用户信息
    useInfo: {},
    //当前公司
    companyInfo: {},
    cartNum: 0,

    chooseCompanyNum:1,
    isShow:false,
    allTopic:false,
    addCompany:false,
    chooseCompany:false
  },
  mutations: {
    //选择次数
    updataNum(state, num){
      state.chooseCompanyNum = num;
    },

    // 保存用户信息
    saveUseInfo(state, info){
      state.useInfo = info;
    },
    //保存当前选中的公司信息
    saveCompanyInfo(state, info){
      state.companyInfo = info;
    },
    // 保存未登录时缓存中购物车数量,登录时同理
    saveCartNum (state, info){
      state.cartNum = info;
    },
   
    /* 关注更多话题 显示弹框 */
    openTopic(state) {
        document.documentElement.style.overflowX = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
        state.allTopic = true;
    },
    /* 关注更多话题 隐藏弹框 */
    closeTopic(state) {
        document.documentElement.style.overflowX = 'scroll';
        document.documentElement.style.overflowY = 'scroll';
        state.allTopic = false;
    },
    /* 新增公司 显示弹框 */
    openAddCompany(state){
      // console.log(state)
      state.addCompany = true;
    },
    /* 新增公司 隐藏弹框 */
    closeAddCompany(state){
        state.addCompany = false;
    },
    /* 工作台选择公司 显示弹框 */
    openCompany(state){
        state.chooseCompany = true;
    },
      /* 工作台选择公司 隐藏弹框 */
    closeCompany(state){
        state.chooseCompany = false;
    },
  },
  actions: {
    //通过mutations来改变state的属性状态 
    // context上下文 commit提交
    // 获取当前登录用户信息
    async addCartAsync(context){
      // console.log(sessionStorage.getItem('myCartList'))
      if(sessionStorage.getItem('myCartList')){
        let myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        let mealParams = [];
        let healthParams = [];
        myCartList.forEach((item)=>{
          if(item.orderType==4){
            let obj = {
              MealID: item.mealID,
              MealCount: item.mealCount,
            }
            healthParams.push(obj);
          }else{
            let obj = {
              mealID: item.mealID,
              mealCount: item.mealCount,
              orderType:item.orderType,
            }
            mealParams.push(obj)
          }
        })
        if(mealParams&&mealParams.length>0){
          let mealRes = await getReqAddCart(mealParams);
          if(mealRes.isSuccess){
            sessionStorage.removeItem('myCartList');
            // 调用
            context.dispatch('getLoginCartNum');
            // 调用获取登录时的购物车的数量
          }else{
            Message.error('同步购物车数据失败')
          }
        }
        if(healthParams&&healthParams.length>0){
          // console.log(healthParams)
          let healthRes = await getHealthReqAddCart(healthParams);
          console.log('调用1次')
          if(healthRes.isSuccess){
            sessionStorage.removeItem('myCartList');
            // 调用
            context.dispatch('getLoginCartNum');
            // 调用获取登录时的购物车的数量
          }else{
            Message.error('同步购物车数据失败')
          }
        }
      }else{
        context.dispatch('getLoginCartNum');
      }
    },
    // 获取未登录时缓存中购物车数量
    getNoLoginCartNum (context) {
      if(sessionStorage.getItem('myCartList')){
        var myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        context.commit('saveCartNum', myCartList.length);
      }
    },
    // 获取已登录状态购物车数量
    async getLoginCartNum (context){
      let cartRes = await getReqCartNum()
      if(cartRes.code==200){
        context.commit('saveCartNum',cartRes.data)
      }else if(mealRes.code == 403){
        console.log(mealRes.msg)
      }else{
        Message.error(mealRes.msg);
      }
    },
    //获取已登录状态购物车
    async getLoginCartList(content){
      let res = await getReqMyCart();
      if(res.code==200){
        //console.log(res)
        window.location.reload();
      }else if(res.code==403){
        console.log(res.msg)
      }else{
        Message.error(res.msg);
      }
    }
  },
  modules: {
  }
})
